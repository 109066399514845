import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { ButtonOutlined, ButtonPrimary, Footer } from '@components';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';
import { Create, Return } from 'images/Goal';
import { Copy, Person, Time, Remove } from '@images';
import Addicon from '@material-ui/icons/Add';
import FoundDepositDesktop from '@components/Structural/Founds/FoundDeposit';
import FoundDepositMobile from '@components/Structural/Founds/FoundDepositMobile';
import { useAuth, useURLParams } from '@hooks';
import { useGoals, useUserInvestments, usePortfolios } from '@apollo';
import Successful from '@components/Structural/Successful/Successful';

const Founds = () => {
  useAuth({ redirectIfNotLogged: true });
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1280px)');
  const [params, setParams] = useState(undefined);
  const { goals } = useGoals();
  const [goalName, setGoalName] = useState(undefined);
  const [fundName, setFundName] = useState(undefined);
  const [amount, setAmount] = useState(undefined);
  const { portfolios, loading: loadingPortfolios } = usePortfolios();
  const { investments, loading: loadingInvestments } = useUserInvestments();

  useEffect(() => {
    setParams(useURLParams());
  }, []);

  useEffect(() => {
    if (params !== undefined) {
      const goalId = params.filter((obj) => obj.goalId)[0].goalId;
      const goal = goals.filter((obj) => obj.id == goalId)[0];
      if (goal !== undefined) {
        setGoalName(goal.name);
      }
      setAmount(
        parseInt(params.filter((obj) => obj.amount)[0].amount).toLocaleString(
          'es'
        )
      );
      const fundId = params.filter((obj) => obj.fundId)[0].fundId;
      const fund = [...portfolios, ...investments].filter(
        (obj) => obj.id == fundId
      );
      if (fund.length != 0) {
        if (fund[0].__typename == 'Portfolio') {
          setFundName(fund[0].name);
        } else {
          setFundName(fund[0].fund.name);
        }
      }
    }
  }, [goals, params]);

  const [isSuccess, setSuccess] = useState(false);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Los meses se indexan desde 0, por lo que se suma 1
  const day = currentDate.getDate();
  const twoDaysLater = new Date(currentDate);
  const dateTwo = new Date(twoDaysLater.setDate(currentDate.getDate() + 2));
  const yeardateTwo = dateTwo.getFullYear();
  const monthdateTwo = dateTwo.getMonth() + 1; // Los meses se indexan desde 0, se le suma 1
  const daydateTwo = dateTwo.getDate();

  return isSuccess ? (
    <Successful
      title="Tu depósito se ha creado con éxito"
      depositValue={9}
      firstFundSelection=""
    >
      <section className={classes.containerChildren}>
        <div className={classes.dataRetire}>
          <div className={classes.step}>
            <img src="https://storage.googleapis.com/rokinapp-images/investments/CreateNew/check_icon%20(6).svg" />
            <div>
              <span className="title">Depósito creado</span>{' '}
              <span className="subtitle">
                {day}/{month}/{year}
              </span>
            </div>
          </div>
          <div className={classes.line} />
          <div className={classes.step}>
            <img src="https://storage.googleapis.com/rokinapp-images/investments/CreateNew/inProgress_icon.svg" />
            <div>
              <span className="title">
                Esperando confirmación de transferencia
              </span>
              <span className="subtitle">
                Fecha estimada: {daydateTwo}/{monthdateTwo}/{yeardateTwo}{' '}
              </span>
            </div>
          </div>
          <div className={classes.line2} />
          <div className={classes.step}>
            <img src="https://storage.googleapis.com/rokinapp-images/investments/CreateNew/inProgress_icon.svg" />
            <div>
              <span className="title">Depósito completo</span>
              <span className="subtitle">
                Fecha estimada: {daydateTwo}/{monthdateTwo}/{yeardateTwo}{' '}
              </span>
            </div>
          </div>
        </div>
        <div className={classes.button}>
          <ButtonPrimary
            text="¡Listo!"
            onClick={() => {
              navigate(Route.investments);
            }}
          />
        </div>
      </section>
    </Successful>
  ) : (
    <FoundDepositMobile
      amount={amount}
      goalName={goalName}
      fundName={fundName}
      setSuccess={setSuccess}
    />
  );
};
export default Founds;

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    fontFamily: 'Montserrat',
    '& .radio': {
      color: theme.palette.primary.main,
    },
    '& >span': {
      color: theme.palette.primary.main,
    },
  },
  dataRetire: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    padding: '3rem 0rem 0',
    margin: '0 auto',
    width: '19rem',
    position: 'relative',
  },
  step: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    '& >div': {
      gap: '0.2rem',
      display: 'flex',
      flexDirection: 'column',
      '& .subtitle': {
        fontWeight: 300,
        fontFamily: 'Nunito',
        fontSize: '0.75rem',
      },
      '& .title': {
        fontWeight: 500,
        fontFamily: 'Nunito',
        fontSize: '0.75rem',
      },
    },
  },
  textBottom: {
    fontFamily: 'Montserrat',
    color: 'white',
    width: '18.75rem',
    margin: '4rem auto 2rem',
    textAlign: 'center',
  },
  button: {
    margin: '4rem auto',
    width: '17.8rem',
    color: '#282828!important',
    '& >button': {
      width: '17.8rem',
      background: 'rgba(277, 277, 277, 0.8)',
      color: '#282828!important',
      fontWeight: 300,
    },
  },
  line: {
    position: 'absolute',
    width: 1,
    height: '2.5rem',
    borderRight: '1px solid #54e5ff',
    top: '5.2rem',
    left: 10,
  },
  line2: {
    position: 'absolute',
    width: 1,
    height: '2.5rem',
    borderRight: '1px solid #979799',
    top: '9.6rem',
    left: 10,
  },
}));
