import React, { useContext, useCallback, useState } from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { MovementType, PortfolioLocationCategory } from '@interfaces';
import { ProfileContext, NewInvestContext, AppContext } from '@context';
import { useGoals, useCurrentUser } from '@apollo';

interface CreateGoalProps {
  depositValue?: number;
  firstFundSelection: string;
  children: React.ReactNode;
  title: string;
}

const Successful: React.FC<CreateGoalProps> = ({
  depositValue,
  firstFundSelection,
  children,
  title,
}) => {
  const isMobile = useMediaQuery('(max-width: 1280px)');
  const classes = useStyles();
  const { openAddFundsModal, startFlow } = useContext(NewInvestContext);
  const { goals } = useGoals();
  const [goalId, setGoalId] = useState<number>(null);
  const [iconSelected, setIconSelected] = useState<any>(
    'https://storage.googleapis.com/rokinapp-images/investments/CreateNew/otroAvatar.svg'
  );
  const { openNoGoalWarningModal, openNoPersonalDataWarningModal } =
    useContext(AppContext);
  const { profileForm } = useContext(ProfileContext);
  const { user: currentUser } = useCurrentUser();

  const handleClick = useCallback(() => {
    if (goals.length === 0) {
      openNoGoalWarningModal();
    } else {
      if (
        !currentUser?.isIdentityValidated ||
        !currentUser?.isPersonalDataCompleted ||
        !currentUser?.isSpecialDataCompleted
      ) {
        openNoPersonalDataWarningModal();
      } else {
        openAddFundsModal(goalId, false);
        startFlow({
          movementType: MovementType.DEPOSIT,
          newInvestorProfile: firstFundSelection,
          suggestedPortfolio: true,
          investmentLocation: PortfolioLocationCategory.CHILE_AND_WORLD,
          suggestedPortfolioAmount: depositValue,
        });
      }
    }
  }, [goalId]);

  return (
    <>
      <section className={classes.containerData}>
        <div>
          <div className={classes.top}>
            <div className={classes.title}>🎉 {title}</div>

            <div className={classes.imgMobile}>
              <img
                src={
                  'https://storage.googleapis.com/rokinapp-images/investments/CreateNew/Screen%20Shot%202023-05-15%20at%2000.46.13.png'
                }
              />
            </div>
          </div>
          <div className={classes.cardGreen}>
            <div className={classes.blockContainer}>{children}</div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Successful;

const useStyles = makeStyles((theme) => ({
  containerData: {
    display: 'flex',
    background: theme.palette.primary.main,
    fontFamily: 'Montserrat',
    '& >div': {
      width: '100%',
    },
    paddingLeft: 0,
    paddingTop: 0,
    justifyContent: 'center',
  },
  blockContainer: {
    height: '10rem',
  },
  title: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: '0.875rem',
    margin: '3rem auto 2rem',
    textAlign: 'center',
  },
  cardGreen: {
    background: '#00323c',
    borderRadius: '20px 20px 0 0',
    minHeight: '64vh',
  },
  subtitle: {
    width: 350,
    padding: '30px 0 30px',
    fontSize: '0.875rem',
    textAlign: 'center',
    color: 'white',
    margin: '0 auto',
  },
  dataRight: {
    height: '7rem',
    background: 'white',
    boxShadow: '1px 5px 10px rgba(0,0,0,0.2)',
    borderRadius: 16,
    marginBottom: '3rem',
    width: 350,
    margin: '1rem auto 3rem',
    display: 'flex',
    alignItems: 'center',

    '& img': {
      width: 67,
      margin: 18,
    },
  },
  right: {
    paddingLeft: '0.5rem',
    '& span': {
      display: 'block',
    },
  },
  goal: {
    paddingTop: '0.5rem',
    display: 'flex',
    gap: '2rem',
    '& .title': {
      fontSize: '0.875rem',
      fontWeight: 400,
      fontFamily: 'Montserrat',
      display: 'flex',
      gap: '0.2rem',
      alignItems: 'center',
      '& svg': {
        width: 20,
        color: theme.palette.primary.main,
      },
    },
    '& .subtitle': {
      fontSize: 12,
      fontWeight: 500,
      color: '#6B6B6B',
      paddingTop: '0.2rem',
    },
  },
  button: {
    justifyContent: 'space-between',
    display: 'block',
    width: 350,
    margin: '0 auto',
    '& > button': {
      margin: '0 auto 14px',
      width: '100%',
      fontSize: 16,
      height: 50,
      color: '#54e5ff',
    },
  },
  imgMobile: {
    '& img': {
      margin: '0 auto 2rem',
      width: '250px',
    },
  },
  top: {
    minHeight: '30vh',
  },
}));
