import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { ButtonOutlined, ButtonPrimary } from '@components';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';
import { LayoutMobileNewVersion } from '../Goal/NewVersion/LayoutMobile';

const FoundDepositMobile = ({ amount, goalName, fundName, setSuccess }) => {
  const classes = useStyles();

  const handleCopyClick = (textToCopy: string) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log('Contenido copiado al portapapeles:');
      })
      .catch((error) => {
        console.error('Error al copiar al portapapeles:', error);
      });
  };
  return (
    <LayoutMobileNewVersion
      className={classes.headerLayout}
      icon={
        'https://storage.googleapis.com/rokinapp-images/investments/CreateNew/expense_icon%20(1).svg'
      }
      pageName={'Depositar'}
      subtitle="Transferencia"
      onBackClick={() => {
        navigate(Route.investments);
      }}
    >
      <div className={`${classes.container}`}>
        <div className={classes.invest}>
          Invertirás <strong>$ {amount}</strong> en
          <strong> {fundName}</strong>
          para el objetivo de '<strong>{goalName}</strong>'
        </div>
        <div className={classes.cardDeposit}>
          <div className="title">Deposita tu inversión en:</div>
          <div>
            <span>Vector Capital Corredores de Bolsa Spa</span>
            <img
              onClick={() =>
                handleCopyClick('Vector Capital Corredores de Bolsa Spa')
              }
              src={
                'https://storage.googleapis.com/rokinapp-images/investments/CreateNew/copy_icon.svg'
              }
            />
          </div>
          <div>
            <span> 76.513.680-6</span>{' '}
            <img
              onClick={() => handleCopyClick('76.513.680-6')}
              src={
                'https://storage.googleapis.com/rokinapp-images/investments/CreateNew/copy_icon.svg'
              }
            />
          </div>
          <div>
            <span>Cuenta Corriente </span>
          </div>
          <div>
            <span>Banco BICE</span>{' '}
            <img
              onClick={() => handleCopyClick('Banco BICE')}
              src="https://storage.googleapis.com/rokinapp-images/investments/CreateNew/copy_icon.svg"
            />
          </div>
          <div>
            <span>01372491 </span>{' '}
            <img
              onClick={() => handleCopyClick('01372491')}
              src="https://storage.googleapis.com/rokinapp-images/investments/CreateNew/copy_icon.svg"
            />
          </div>
          <div>
            <span>hola@rokinapp.com</span>{' '}
            <img
              onClick={() => handleCopyClick('hola@rokinapp.com')}
              src="https://storage.googleapis.com/rokinapp-images/investments/CreateNew/copy_icon.svg"
            />
          </div>
          <div className={classes.containerButtons}>
            <ButtonOutlined
              className="button"
              onClick={() => {
                handleCopyClick(
                  'Vector Capital Corredores de Bolsa Spa, 76.513.680-6, Banco BICE, 01372491, hola@rokinapp.com'
                );
              }}
              startIcon={
                <img src="https://storage.googleapis.com/rokinapp-images/investments/CreateNew/copy_icon.svg" />
              }
              text="Copiar datos"
            />
            <ButtonPrimary
              className="button"
              onClick={() => {
                setSuccess(true);
              }}
              text="Ya transferí"
            />{' '}
          </div>
        </div>

        <div className={classes.lastBox}>
          <div>
            <img
              src={
                'https://storage.googleapis.com/rokinapp-images/investments/CreateNew/user02_icon.svg'
              }
            />
            <span>
              Recuerda depositar desde una cuenta con el mismo RUT que te
              registraste
            </span>
          </div>
          <div>
            <img
              src={
                'https://storage.googleapis.com/rokinapp-images/investments/CreateNew/clock_icon%20(1).svg'
              }
            />
            <span>
              Tu inversión estará lista en aproximadamente 2 días hábiles
            </span>
          </div>
          <div>
            <img
              src={
                'https://storage.googleapis.com/rokinapp-images/investments/CreateNew/search_icon%20(1).svg'
              }
            />
            <span>Revisa el estado de tus depósitos en “Mis inversiones”</span>
          </div>
        </div>
        <div className={classes.bottom}>
          Recuerda que tenemos una alianza con Vector Corredora de Bolsa S.A,
          por lo que invertirás a través de ellos.
        </div>
      </div>
    </LayoutMobileNewVersion>
  );
};
export default FoundDepositMobile;
const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    fontFamily: 'Montserrat',
    paddingTop: '1rem',
  },
  invest: {
    fontSize: '0.75rem',
    color: 'white',
    width: 350,
    padding: 20,
    margin: '0 auto',
    textAlign: 'center',
  },
  cardDeposit: {
    gridArea: 'cardDeposit',
    background: 'white',
    minWidth: 350,
    width: '90%',
    height: 470,
    borderRadius: 20,
    padding: '20x 30px',
    margin: ' 0 auto',
    [theme.breakpoints.up(1100)]: {
      width: '100%',
      maxWidth: 700,
    },
    '& > .title': {
      color: theme.palette.text.primary,
      fontSize: 20,
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      paddingTop: '1rem',
      [theme.breakpoints.up(1100)]: {
        paddingTop: '2rem',
      },
    },
    '& >div': {
      display: 'flex',
      justifyContent: 'space-between',
      width: 300,
      fontWeight: 500,
      margin: '0 auto',
      padding: '10px 0',
      [theme.breakpoints.up(1100)]: {
        width: '100%',
        maxWidth: 500,
      },
      '& >span': {
        width: 200,
        fontSize: '0.875rem',
        color: '#646464',
        fontWeight: 300,
      },
    },
    '& .button': {
      width: 255,
      marginTop: '1rem',
      display: 'flex',
      margin: '0 auto',
    },
    '& img': {
      cursor: 'pointer',
    },
  },
  deposit: {
    display: 'flex',
    flexFlow: 'column',
    gridArea: 'deposit',
    background: 'white',
    boxShadow: '1px 1px 30px 0 grey',
    width: 350,
    height: 150,
    borderRadius: 30,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    marginTop: '2rem',
    '& >span': {
      display: 'block',
      fontWeight: 'bold',
      fontSize: 24,
      paddingBottom: '0.5rem',
    },
    '&>span:last-child': {
      fontSize: 14,
      display: 'block',
      fontWeight: 400,
      paddingTop: '1rem',
      color: theme.palette.text.secondary,
    },
    '& button': {
      fontWeight: 500,
      width: 250,
    },
  },
  addIcon: {
    width: '120px!important',
    position: 'absolute',
    right: 20,
    top: 10,
  },
  lastBox: {
    gridArea: 'lastBox',
    border: `1px solid ${theme.palette.primary.main}`,
    minWidth: 350,
    width: '90%',
    maxWidth: 700,
    height: 220,
    borderRadius: 30,
    padding: '10px 20px',
    marginTop: '2rem',
    marginBottom: '2rem',
    margin: '0 auto',
    display: 'grid',
    fontSize: 14,
    color: 'white',
    [theme.breakpoints.up(1100)]: {},
    '&>div': {
      '& img': {
        width: 32,
        height: 32,
      },
      display: 'flex',
      fontWeight: 300,
      alignItems: 'center',
      minWidth: 300,
      maxWidth: 700,
      width: '90%',
      gap: '1rem',
      color: 'white',
      [theme.breakpoints.up(1100)]: {},

      '& >span': {
        paddingLeft: '1rem',
        fontSize: '0.75rem',
      },
    },
  },
  headerLayout: {
    background: '#00323c',
    '& >section': {
      height: '7rem',
    },
  },
  header: {
    background:
      'linear-gradient(161deg, rgb(0, 170, 188) 30%, rgb(5, 130, 149) 63%, rgb(10, 86, 105) 88%)',
    paddingTop: 30,
    borderRadius: '0 0 30px 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    paddingBottom: 15,
    marginBottom: 20,
    '& >img': {
      width: 30,
      marginLeft: '1rem',
    },
    '& >div': {
      paddingLeft: '3rem',
    },
  },
  titleHeader: {
    color: 'white',
    fontSize: 20,
  },
  bottom: {
    minWidth: '15.6rem',
    width: '90%',
    maxWidth: 700,
    margin: '0 auto',
    fontSize: '0.75rem',
    fontWeight: 400,
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Montserrat',
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(1100)]: {
      width: '100%',
      display: 'flex!important',
      maxWidth: 700,
      gap: '1rem',
      flexDirection: 'row!important',
      justifyContent: 'center!important',
    },
  },
}));
